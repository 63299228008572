<template>
    <div v-if="successMessage" class="bg-green-600 border border-green-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ successMessage }}</div>
    <div v-if="errorMessage" class="bg-red-600 border border-red-600 my-[10px] text-white text-center fixed md:top-[80px] top-[60px] shadow-lg left-[50%] translate-x-[-50%] font-bold flex justify-center items-center z-30 rounded-[5px] max-w-[450px] w-[90%] p-[20px] ">{{ errorMessage }}</div>
<div>
    <div class="md:flex py-[20px] px-[10px] md:p-[100px] bg-gray-950 ">
        <div class="md:w-4/12 md:px-[20px] p-[10px] text-left ">
            <h1 class="md:mb-[20px] mb-[10px] uppercase md:text-2xl font-bold text-white ">{{ $t('new') }}</h1>  
            <form @submit.prevent="subscribe"> 
                <div class="block">
                    <input type="text" class="w-[90%] max-w-[250px] block my-[5px] border text-white rounded-[5px] py-[5px] px-[10px] outline-none border-teal-900 bg-gray-950 " placeholder="Entrer votre Email" required  v-model="email">
                    <button type="submit" :disabled="loading" class="w-[90%] max-w-[250px] capitalize block my-[10px] p-[5px] cursor-pointer md:p-[10px] rounded-[10px] text-black bg-white ">
                        <i v-if="loading" class="fas fa-spinner fa-spin"></i>
                        <span v-else>{{ $t('subscribe') }}</span>
                    </button>
                </div>
            </form>
        </div>
        <div class="md:w-4/12 md:px-[20px] p-[10px] text-left text-white ">
            <h1 class="md:mb-[20px] mb-[10px] uppercase md:text-2xl font-bold text-white ">{{ $t('link') }}</h1>  
            <div class="block">
                <router-link to="/Politique" class=" py-[5px] block " >{{ $t('private') }}</router-link>
                <router-link to="/Conditions" class=" py-[5px] block " >{{ $t('term') }}</router-link>
            </div>
        </div>
        <div class="md:w-4/12 md:px-[20px] p-[10px] text-left text-white ">
            <h1 class="md:mb-[20px] mb-[10px] uppercase font-bold md:text-2xl text-white ">{{ $t('get') }}</h1>  
            <div class="flex py-[5px] ">
                <i class="fa-solid fa-location-dot mt-[5px] mr-[10px]"></i>
                <span>
                    <span class="font-bold">Adresse</span> : {{ $t('adresse') }}
                </span>
            </div>
            <div class="flex py-[5px] ">
                <img src="https://flagcdn.com/w40/bj.png" alt="Benin flag" class="w-4 h-4 mt-[5px] mr-[10px]" />
                <a href="https://wa.me/22943480141">Tel : +229 43 48 01 41</a>
            </div>
            <div class="flex py-[5px] ">
                <img src="https://flagcdn.com/w40/ci.png" alt="Benin flag" class="w-4 h-4 mt-[5px] mr-[10px]" />
                <a href="https://wa.me/2250716900836">Tel : +225 07 16 90 08 36</a>
            </div>
            <div class="flex py-[5px] ">
                <i class="fa-solid fa-envelope mt-[5px] mr-[10px] "></i>
                <a href="mailto:service.client@ironsecur.com">service.client@ironsecur.com</a>
            </div>
            <div class="flex flex-col py-[5px] ">
                <h1 class="text-xl font-bold uppercase my-[10px]">{{ $t('follow') }}</h1>
                <div>
                    <a target="_blank" href="https://wa.me/+22943480141"><i class="mr-[10px] text-2xl  fa-brands fa-whatsapp"></i></a>
                    <a target="_blank" href="https://www.facebook.com/profile.php?id=61553766524853"><i class="mr-[10px] text-2xl  fa-brands fa-facebook"></i></a>
                    <!--
                    <a target="_blank" href="#"><i class="mr-[10px] text-2xl  fa-brands fa-instagram"></i></a>
                    -->
                    <a target="_blank" href="https://www.linkedin.com/company/ironsecur/"><i class="mr-[10px] text-2xl  fa-brands fa-linkedin"></i></a>
                    <a target="_blank" href="https://twitter.com/ironsecur"><i class="mr-[10px] text-2xl  fa-brands fa-twitter"></i></a>
                </div>
            </div>
        </div>
    </div>
    <div class="w-screen h-[70px] md:flex text-center justify-between items-center px-[20px] md:px-[100px] text-black font-bold ">
        <img src="../assets/img/logo.png" class="h-16 md:mx-0 my-[10px] mx-auto block" alt="">
        <span class="my-[10px] md:mx-0 mx-auto block"> &copy; 2024 IronSecur, Tous droits réservés.</span>
    </div>
</div>
</template>

<script>
import axios from 'axios'
import axiosInstance from '@/plugins/axios';

export default {
    data() {
        return {
            email: '',
            loading: false,
            successMessage: '',
            errorMessage: ''
        };
    },
    methods: {
        subscribe() {
            this.loading = false;

            axiosInstance.post('/subscribers/', { email: this.email })
                .then(response => {
                    if (response.status === 200 || response.status === 201 || response.status === 204) {
                        this.successMessage = 'Email envoyé avec succès !';
                        this.hideSuccessMessageAfterDelay();
                    } else {
                        this.successMessage = '';
                    }
                
                    this.email = '';
                })
                .catch(error => {
                    this.loading = false;
                    console.error(error);
                    if (error.response && error.response.status === 400 && error.response.data && error.response.data.email && error.response.data.email[0] === 'This email adress has already used') {
                        this.successMessage = 'Cet email est déjà enregistré.';
                        this.hideSuccessMessageAfterDelay();
                        this.email = '';
                    } else {
                        this.errorMessage = 'Une erreur s\'est produite. Veuillez réessayer.';
                        this.hideErrorMessageAfterDelay();
                    }
                });
        },

        hideSuccessMessageAfterDelay() {
          setTimeout(() => {
            this.successMessage = ''; 
          }, 5000);
        },
        hideErrorMessageAfterDelay() {
          setTimeout(() => {
            this.errorMessage = ''; 
          }, 5000);
        },
    },
};
</script>

<style>

</style>