<template class="max-w-screen">
    <div class="flex font-montserrat flex-col justify-center items-center">
        <HeaderComponent/>
        <div class="lg:h-[80px] h-[50px]"></div>
        <div class="w-full lg:flex hidden h-auto relative">
            <div class="w-6/12 flex relative justify-center items-center">
                <img src="../assets/img/Vector2.svg" alt="" class="w-full h-full object-cover">
                <div class="absolute top-0 left-0 text-white right-0 w-full h-full flex p-[50px] pt-[100px] 2xl:pt-[200px] justify-start items-start flex-col">
                    <h1 class="uppercase text-4xl leading-[50px] font-bold">INTRODUCTION à LA CYBERSÉCURITÉ</h1>
                    <p class="my-[5px]"><b>Durée : </b> 14 semaines</p>
                    <p class="my-[5px]"><b>Certification cible : </b> CompTIA’s Security+</p>
                    <p class="my-[5px]"><b>NIVEAU : </b> DEBUTANT/INTERMÉDIAIRE </p>
                    <div class="flex w-full mt-[100px] justify-starts items-center">
                        <router-link to="/contacts" class="border-2 border-white text-white p-[10px] text-center uppercase rounded-[5px]">
                            Contactez nous
                        </router-link>
                        <a href="/Document/Cybersecurity_Training_Content.pdf" download="Cybersecurity_Training_Content.pdf" class="bg-white border-2 border-white flex justify-center items-center ml-[20px] text-cyan-500 p-[10px] text-center uppercase rounded-[5px]">
                            <i class="fa-solid fa-download mr-[10px]"></i>
                            Programme
                        </a>
                    </div>
                </div> 
            </div>
            <div class="w-6/12 flex relative justify-center items-center">
                <img src="../assets/img/cheerful.png" alt="" class="top-[-50px] left-[-50px] z-[-1] h-full object-cover w-full relative">
            </div>
        </div>
        <div class="w-full lg:hidden flex relative h-[300px] md:h-[400px]">
            <img src="../assets/img/fond.jpg" alt="" class="w-full h-full">
            <div class="absolute top-0 right-0 left-0 w-full text-white text-xs h-full flex justify-start pt-[50px] items-center flex-col bg-black/50">
                <h1 class="uppercase md:text-2xl text-sm text-center font-bold">INTRODUCTION A LA CYBERSÉCURITÉ</h1>
                <p class="my-[5px]">Durée : 14 semaines</p>
                <p class="my-[5px]">Certification cible : CompTIA’s Security</p>
                <p class="my-[5px]">NIVEAU : DEBUTANT/INTERMÉDIAIRE </p>
                <div class="mt-[20px] w-full flex justify-center items-center">
                    <router-link to="/contacts" class="text-white border-white border-2 p-[5px] text-center text-xs font-bold uppercase rounded-[5px]">
                        Contactez nous
                    </router-link>
                    <a href="/Document/Cybersecurity_Training_Content.pdf" download="Cybersecurity_Training_Content.pdf" class="bg-white border-2 border-white ml-[10px] flex justify-center items-center text-xs font-bold text-cyan-500 p-[5px] text-center uppercase rounded-[5px]">
                        <i class="fa-solid fa-download mr-[5px]"></i>
                        Programme
                    </a>

                </div>
            </div>
        </div>
        <div class="container max-w-[1024px] px-[10px] pt-[50px] md:mb-[-200px] mb-[-125px]">
            <div class="w-full flex justify-center items-center relative top-[-125px] md:top-[-210px]">
                <div class="md:w-[320px] md:p-[25px] p-[10px] md:h-[300px] flex flex-col justify-start items-start w-[100px] h-[100px] border md:m-[15px] m-[10px] shadow-xl bg-white">
                    <div class="md:w-[50px] w-[35px] md:h-[50px] h-[35px] rounded-[50px] flex justify-center items-center bg-cyan-500 text-white">
                        <i class="fa-solid fa-pencil"></i>
                    </div>
                    <h1 class="my-[10px] font-bold md:text-xl text-xs">Théorie</h1>
                    <div class="md:w-[100px] w-[50px] h-[4px] bg-cyan-500"></div>
                    <div class="hidden md:flex mt-[20px]">
                        Nous vous offrons un matériel pédagogique détaillé.
                    </div>
                </div>
                <div class="md:w-[320px] md:p-[25px] p-[10px] md:h-[300px] flex flex-col justify-start items-start w-[100px] h-[100px] border md:m-[15px] m-[10px] shadow-xl bg-white">
                    <div class="md:w-[50px] w-[35px] md:h-[50px] h-[35px] rounded-[50px] flex justify-center items-center bg-cyan-600 text-white">
                        <i class="fa-solid fa-code"></i>
                    </div>               
                    <h1 class="my-[10px] font-bold md:text-xl text-xs">Pratique</h1>
                    <div class="md:w-[100px] w-[50px] h-[4px] bg-cyan-500"></div>
                    <div class="hidden md:flex mt-[20px]">
                        Notre plateforme de défis vous propose une diversité étendue de challenges.
                    </div>
                </div>
                <div class="md:w-[320px] md:p-[25px] p-[10px] md:h-[300px] flex-col justify-start items-start w-[100px] h-[100px] flex border md:m-[15px] m-[10px] shadow-xl bg-white">
                    <div class="md:w-[50px] w-[35px] md:h-[50px] h-[35px] rounded-[50px] flex justify-center items-center bg-cyan-500 text-white">
                        <i class="fa-solid fa-briefcase"></i>
                    </div>
                    <h1 class="my-[10px] font-bold md:text-xl text-xs">Immersion</h1>
                    <div class="md:w-[100px] w-[50px] h-[4px] bg-cyan-500"></div>
                    <div class="hidden md:flex mt-[20px]">
                        Passer du temps en entreprise et comprenez les responsabilités qui seront les vôtres .
                    </div>
                </div>
            </div>
        </div>
        <div class="container max-w-[1024px] py-[20px] px-[10px] w-full ">
            <div class="w-[50px] h-[4px] bg-cyan-500"></div>
            <h1 class="uppercase text-2xl font-bold my-[10px]">Notre formation</h1>
            <div class="text-gray-700 font-normal">
                Nous proposons un programme de formation “Introduction à la cybersécurité” destiné 
                aux  novices comme aux professionnels avec pour objectifs principaux l’acquisition 
                des compétences essentielles pour obtenir la certification Security+ de CompTIA et 
                une préparation au métier d’analyste en cybersécurité. Ce programme s'étend sur 
                quatorze (14) semaines et est composé de sessions de travail animées par des instructeurs 
                qualifiés. Des sessions en anglais sont intégrées pour renforcer votre compréhension 
                des sujets, accompagnées d'épreuves pratiques disponibles sur notre plateforme 
                d'apprentissage. De surcroît, une immersion en entreprise est prévue pour vous 
                permettre de mettre en pratique vos acquis.
            </div>
        </div>
        <div class="container max-w-[1024px] py-[20px] px-[10px] w-full ">
            <h1 class="uppercase rounded-t-[10px] p-[10px] font-bold my-[10px] w-full bg-cyan-400 text-black">Avantages pour vous</h1>
            <ul class="border relative top-[-10px] py-[20px] rounded-b-[10px] border-t-none">
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Acquisition des compétences essentielles pour obtenir la certification Security+ de CompTIA;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Certification reconnue pour améliorer votre attractivité sur le marché de l'emploi;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Formation de qualité sur 14 semaines, encadrée par des instructeurs compétents;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Renforcement de la compréhension grâce à des sessions en anglais et des exercices pratiques en ligne;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Expérience pratique grâce à une immersion en entreprise à la fin de la formation;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Accompagnement personnalisé tout au long du processus d'apprentissage.
                    </p>
                </li>
            </ul>
        </div>
        <div class="container max-w-[1024px] py-[20px] px-[10px] w-full ">
            <h1 class="uppercase rounded-t-[10px] p-[10px] font-bold my-[10px] w-full bg-cyan-400 text-black">Durée et détails organisationnels</h1>
            <ul class="border relative top-[-10px] py-[20px] rounded-b-[10px] border-t-none">
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        <b>Durée : </b> 14 semaines
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        <b>Mobilité : </b> En ligne
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        <b>Certification cible : </b> CompTIA’s Security+
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        <b>Titre du poste cible : </b> Analyste en cybersécurité
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        <b>Modalité de la formation : </b> Veuillez nous contacter via nos différents canaux pour plus de détails.
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        <b>Prix du bon de composition pour la certification : </b> Veuillez consulter le site de <a target="_blank" href="https://www.comptia.org/testing/exam-vouchers/buy-exam/exam-prices#security" class="underline">CompTIA</a> pour obtenir cette information ou nous contacter pour de plus amples explications.
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        <b>Langue des supports : </b> Anglais
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        <b>Langue des séances de formation : </b> Français
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        <b>Créneau : </b> En soirée. Des options en journée sont disponibles mais à discuter.
                    </p>
                </li>
            </ul>
        </div>
        <div class="container max-w-[1024px] py-[20px] px-[10px] w-full ">
            <div class="w-[50px] h-[4px] bg-cyan-500"></div>
            <h1 class="uppercase text-2xl font-bold my-[10px]">Plan du Cours</h1>
            <ul>
                <li class="w-full p-[10px] border-t border-black px-[20px]">
                    <b>Semaine 1 : </b> Introduction à la cybersécurité
                </li>
                <li class="w-full p-[10px] border-t border-black px-[20px]">
                    <b>Semaine 2 : </b> Systèmes d'exploitation et Infrastructure
                </li>
                <li class="w-full p-[10px] border-t border-black px-[20px]">
                    <b>Semaine 3: </b> Gestion de l'identité et des accès
                </li>
                <li class="w-full p-[10px] border-t border-black px-[20px]">
                    <b>Semaine 4: </b> Cryptographie
                </li>
                <li class="w-full p-[10px] border-t border-black px-[20px]">
                    <b>Semaine 5: </b> Fondamentaux des réseaux
                </li>
                <li class="w-full p-[10px] border-t border-black px-[20px]">
                    <b>Semaine 6: </b> Défense du réseau
                </li>
                <li class="w-full p-[10px] border-t border-black px-[20px]">
                    <b>Semaine 7: </b> Cadre d'attaque
                </li>
                <li class="w-full p-[10px] border-t border-black px-[20px]">
                    <b>Semaine 8: </b> Sécurité des produits
                </li>
                <li class="w-full p-[10px] border-t border-black px-[20px]">
                    <b>Semaine 9: </b> L'Humain comme maillon faible
                </li>
                <li class="w-full p-[10px] border-t border-black px-[20px]">
                    <b>Semaine 10: </b> Défi Ironsecur CTF ( Capture The Flag )
                </li>
                <li class="w-full p-[10px] border-t border-b border-black px-[20px]">
                    <b>Semaines 11 à 14: </b> Projet d'entreprise
                </li>
            </ul>
        </div>
        <div class="container max-w-[1024px] py-[20px] px-[10px] w-full ">
            <h1 class="uppercase rounded-t-[10px] p-[10px] font-bold my-[10px] w-full bg-cyan-400 text-black">Les objectifs de cette formation</h1>
            <ul class="border relative top-[-10px] py-[20px] rounded-b-[10px] border-t-none">
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Acquérir une compréhension approfondie du métier d’analyste en cybersécurité;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Établir une connaissance fondamentale des principes clés de la cybersécurité;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Maîtriser les compétences nécessaires pour effectuer des analyses de réseaux et de systèmes afin d'identifier des cibles potentielles;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Explorer des techniques éthiques pour exploiter les vulnérabilités et les faiblesses;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Mettre en œuvre des contre-mesures efficaces pour renforcer la sécurité du système contre les menaces potentielles.
                    </p>
                </li>
            </ul>
        </div>
        <div class="container max-w-[1024px] py-[20px] px-[10px] w-full ">
            <h1 class="uppercase rounded-t-[10px] p-[10px] font-bold my-[10px] w-full bg-cyan-400 text-black">A qui s’adresse cette formation</h1>
            <ul class="border relative top-[-10px] py-[20px] rounded-b-[10px] border-t-none">
                <span class="mx-[10px]"> Ce cours s'adresse à :</span>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Les novices intéressés par la cybersécurité, cherchant une introduction aux principes fondamentaux de la sécurité;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Ceux aspirant à obtenir la certification Security+ de CompTIA;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Les individus qui se sentent déconcertés par des termes techniques complexes en sécurité, mais qui désirent approfondir leur compréhension;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Les professionnels souhaitant maîtriser les concepts, principes et termes de base en sécurité, sans nécessité d'une expertise détaillée;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Les personnes optant pour un changement de carrière afin de saisir les opportunités d'emploi dans le domaine de la cybersécurité;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Les gestionnaires d'entreprise préoccupés par la possibilité que leur entreprise devienne le sujet d'une importante violation de données rendue publique.
                    </p>
                </li>
            </ul>
        </div>
        <div class="container max-w-[1024px] py-[20px] px-[10px] w-full ">
            <h1 class="uppercase rounded-t-[10px] p-[10px] font-bold my-[10px] w-full bg-cyan-400 text-black">Approche pédagogique (Comment opérons-nous)</h1>
            <ul class="border relative top-[-10px] py-[20px] rounded-b-[10px] border-t-none">
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Mélange de présentations théoriques et d'exercices pratiques;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Exemples concrets et études de cas issus du domaine du hacking éthique seront utilisés pour illustrer les concepts;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Mise en application des techniques de hacking à travers des projets concrets;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Discussions et partage d'expériences seront encouragés pour faciliter l'apprentissage collaboratif;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Des compétitions sous le format "Capture de flag" seront lancées pour stimuler l'esprit compétitif;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Vous aurez l'opportunité de mettre en pratique vos compétences professionnelles au sein d'une entreprise, ce qui permettra non seulement de les valoriser auprès de l'employeur, mais aussi de confirmer la fin de votre formation.
                    </p>
                </li>
            </ul>
        </div>
        <div class="container max-w-[1024px] py-[20px] px-[10px] w-full ">
            <h1 class="uppercase rounded-t-[10px] p-[10px] font-bold my-[10px] w-full bg-cyan-400 text-black"> Ressources pédagogiques</h1>
            <ul class="border relative top-[-10px] py-[20px] rounded-b-[10px] border-t-none">
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Un site dédié proposant de nombreux défis de hacking éthique;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Des supports de cours et des présentations pour acquérir des connaissances théoriques;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Des exercices pratiques permettant d'appliquer les techniques de hacking éthique;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Des études de cas concrets pour comprendre les défis et solutions dans des situations réelles;
                    </p>
                </li>
                <li class="my-[5px] ml-[20px] flex justify-start items-start">
                    <i class="fa-solid fa-circle-check text-green-500 mr-[10px] relative top-[5px]"></i>
                    <p>
                        Des outils de hacking éthique et des environnements de programmation pour une pratique concrète, avec des ressources en ligne supplémentaires pour approfondir les connaissances.
                    </p>
                </li>
            </ul>
        </div>
        <div class="container max-w-[1024px] py-[20px] px-[10px] w-full ">
            <div class="w-[50px] h-[4px] bg-cyan-500"></div>
            <h1 class="uppercase text-2xl font-bold my-[10px]">Pourquoi nous choisir</h1>
            <ul>
                <li class="w-full p-[10px] flex justify-start items-center">
                    <i class="fa-solid fa-minus mr-[10px]"></i>
                    Programme de formation encadré par des instructeurs qualifiés;
                </li>
                <li class="w-full p-[10px] flex justify-start items-center">
                    <i class="fa-solid fa-minus mr-[10px]"></i>
                    Sessions en anglais et épreuves pratiques pour renforcer votre compréhension et application des connaissances;
                </li>
                <li class="w-full p-[10px] flex justify-start items-center">
                    <i class="fa-solid fa-minus mr-[10px]"></i>
                    Préparation intensive à la certification CompTIA’s Security+ pour une meilleure employabilité;
                </li>
                <li class="w-full p-[10px] flex justify-start items-center">
                    <i class="fa-solid fa-minus mr-[10px]"></i>
                    Immersion en entreprise pour concrétiser vos compétences professionnelles dans un environnement réel.
                </li>
                <li class="w-full ">
                    Optez pour notre approche collaborative, favorisant le partage d'expériences au sein d'un réseau dynamique, pour un développement professionnel réussi.
                </li>
            </ul>
        </div>
        <div class="container max-w-[1024px] py-[20px] pb-[50px] px-[10px] w-full ">
            <div class="w-[50px] h-[4px] bg-cyan-500"></div>
            <h1 class="uppercase text-2xl font-bold my-[10px]">Nos Ressources</h1>
            <p class="mb-[20px]">Explorez les ressources de formation d'IronSecur</p>
            <div class="grid md:grid-cols-2 grid-cols-1 grid-flow-row gap-8 mt-6">
                <div
                    class="rounded-md border md:h-50 min-h-40 px-2 py-4 flex flex-row"
                >
                    <div>
                    <a href="https://ctf.ironsecur.com/" target="_blank"><span
                        class="px-4 py-1 mr-5 bg-cyan-500 font-extrabold text-2xl text-white inline-block"
                        > <i class="fas fa-flag"></i></span
                    ></a>
                    </div>
                    <div>
                        <h5 class="text-base font-bold mb-2"><a href="https://ctf.ironsecur.com/" target="_blank">Plateforme de CTF (Capture The Flag)</a></h5>
                        <p>Pratiquez vos compétences en sécurité informatique avec nos défis de Capture The Flag.</p>
                    </div>
                </div>
                <div
                    class="rounded-md border md:h-50 min-h-40 px-2 py-4 flex flex-row"
                >
                    <div>
                    <a href="https://training.ironsecur.com/" target="_blank"><span
                        class="px-4 py-1 mr-5 bg-cyan-500 font-extrabold text-2xl text-white inline-block"
                        ><i class="fas fa-chalkboard-teacher"></i></span
                    ></a>
                    </div>
                    <div>
                        <h5 class="text-base font-bold mb-2"><a href="https://training.ironsecur.com/" target="_blank">Plateforme de Formation</a></h5>
                        <p>Formez-vous avec nos cours interactifs et exercices pratiques.</p>
                    </div>
                </div>
            </div>
        </div>
        <FooterComponent/>
    </div>
  </template>
  
  <script setup lang="ts">
  import HeaderComponent from '../components/HeaderComponent.vue'
  import FooterComponent from '@/components/FooterComponent.vue';
  import { ref, onMounted } from 'vue';
  import { useRouter } from 'vue-router';
  const router = useRouter()
  const initialSlide = 1;
  
  </script>
  
  <style scoped>
  </style>